import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { Link as Scroll } from "react-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const navs = [
  {
    title: "料金について",
    link: "price",
  },
  {
    title: "ご契約について",
    link: "contract",
  },
  {
    title: "機能について",
    link: "function",
  },
  {
    title: "サポートについて",
    link: "support",
  },
  {
    title: "キャンペーンについて",
    link: "campaign",
  },
  {
    title: "その他",
    link: "others",
  },
]

const Qa = ({ location }) => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="FAQ | よくいただくご質問"
          pagedesc="PARKLoT利用のTwitterキャンペーン開催に関して、料金・ご契約・機能・サポート・キャンペーン・その他の質問にお答えします。"
          pagepath={location.pathname}
        />
        <main className="qa-list">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container m-auto">
              <h1 className="text-white font-bold">よくいただくご質問</h1>
            </div>
          </section>
          <section className="navigation">
            <div className="container px-4 py-4 md:py-20 md:px-0">
              <ul className="qa-list__nav md:flex md:flex-wrap w-full">
                {navs.map((nav, index) => {
                  return (
                    <li
                      key={index}
                      className="border border-lightgrey rounded bg-white hover:bg-palegrey transition-all md:mb-6 mb-3"
                    >
                      <Scroll
                        to={nav.link}
                        className="p-3 md:pb-2 block"
                        smooth={true}
                      >
                        <div className="text-center flex justify-between align-center md:block">
                          <span className="text-sm text-text font-bold md:block">
                            {nav.title}
                          </span>
                          <FontAwesomeIcon
                            className="text-lightgrey"
                            icon={faAngleDown}
                            size="lg"
                          />
                        </div>
                      </Scroll>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 料金 */}
          <section id="price">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                料金について
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  本当にこの価格で何回もキャンペーンができるのですか？追加費用はかからないのでしょうか。
                </dt>
                <dd className="qa-list__data">
                  定額プランでご契約いただいた場合、追加費用不要で何回でもキャンペーンを行っていただけます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  応募数、当選数、エンゲージメント数に応じた従量課金や、データ取得制限等はありますか?
                </dt>
                <dd className="qa-list__data">
                  現時点では従量課金はありません。
                  <br />
                  ただし、既存フォロワー数が10万名以上のアカウントで、参加見込みが1日で1万名を超える場合は事前にご相談ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーン結果をCSVでダウンロードする際の料金はいくらですか？
                </dt>
                <dd className="qa-list__data">
                  基本料金内でダウンロードしていただけます。別途料金は必要ありません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンを「毎日実施」と「週単位で実施」する場合で、料金は変わりますか？
                </dt>
                <dd className="qa-list__data">
                  いいえ、変わりません。
                  <br />
                  毎日実施（31回/月）でも、毎週実施（4回/月）でも、同一の料金です。※キャンペーンの同時開催はできません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  支払方法・支払サイトについて教えてください。
                </dt>
                <dd className="qa-list__data">
                  システム利用料金はご利用開始前にお支払いいただきます。
                  <br />
                  弊社から請求書を発行しますので、キャンペーン開始の前日までにお支払いください。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  年間契約などで割引はありますか？
                </dt>
                <dd className="qa-list__data">
                  はい、年間契約いただいた際は料金の割引を行っております。
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 契約 */}
          <section id="contract">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                ご契約について
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTでキャンペーンを始めたいのですが、どこで申し込めますか？
                </dt>
                <dd className="qa-list__data">
                  <a
                    className="qa-list__text-link"
                    href="/forms/apply"
                    target="_blank"
                    rel="noreferrer"
                  >
                    こちらのお申し込みフォーム
                  </a>
                  からお申し込みください。お申し込み〜キャンペーン開始までの流れは「お申し込みからキャンペーン開始までの流れ」からご確認ください。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/starting-a-campaign" className="qa-list__link">
                    お申し込みからキャンペーン開始までの流れ
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  申し込みの前にデモ画面等で実際の管理画面の操作や参加者の導線を確認することはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。詳細についてはお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  導入を検討中です。PARKLoTシステムのパンフレットや紹介資料をもらえますか？
                </dt>
                <dd className="qa-list__data">
                  資料ダウンロードページより、PDFファイルですぐにご覧いただけます。
                  <br />
                  紙媒体での送付をご希望のお客様は、
                  <a
                    className="qa-list__text-link"
                    href="https://hub.park-lot.com/contact"
                    target="_blank"
                    rel="noreferrer"
                  >
                    お問い合わせフォーム
                  </a>
                  よりご連絡ください。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://hub.park-lot.com/document-download"
                    className="qa-list__link"
                  >
                    「3分でわかるPARKLoT」をダウンロード
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  契約後にキャンペーン主催者がすべきことは何ですか？
                </dt>
                <dd className="qa-list__data">
                  Twitter
                  API申請の確認と、PARKLoTシステムへのログインをお願いします。
                  <br />
                  ログイン後、管理画面でキャンペーンを作成していただけます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  複数のTwitterアカウントでのキャンペーンを検討しています。契約はアカウント単位でしょうか？それとも企業単位でしょうか？
                </dt>
                <dd className="qa-list__data">
                  Twitterアカウント単位でのご契約となります。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  どんな商品のTwitterアカウントでもキャンペーンを開催できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、基本的にはどんなアカウントでもキャンペーンを開催できます。
                  <br />
                  ただし、ギャンブル関連・アダルトグッズ・仮想通貨などに分類される商品の場合、追加の工数が必要なため別途料金でのご案内になります。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  他社ツールでインスタントウィンを開催しているが、乗り換えを検討しています。見積もりだけでもお願いできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、お見積もりだけでもどうぞご依頼ください。
                  <br />
                  PARKLoTでは、コストを抑え、定額で何回でもインスタントウィンを開催できます。
                  <br />
                  さらに、自社開発のリソースもありますので、他社様で現在開催されているキャンペーンをレベルアップさせるご提案も可能です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  解約のための手続きは必要ですか？
                </dt>
                <dd className="qa-list__data">特に必要ありません。</dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* 機能 */}
          <section id="function">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                機能について
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  定額プランに含まれる機能を教えてください。
                </dt>
                <dd className="qa-list__data">
                  フォロー&リツイート形式のインスタントウィンキャンペーン機能が、月額でご利用いただける基本機能です。また、追加可能な無料オプション機能は下記になります。
                  <br />
                  ・発送先住所またはEメールアドレスの収集
                  <br />
                  ・キャンペーン参加者へのメッセージ自動送信
                  <br />
                  ・WEBサイトへの誘導
                  <br />
                  ・LINEへの誘導
                  <br />
                  ・固定のギフトコード自動送信（Amazonギフトコードなど、当選者毎に異なるコードの自動配布は有料オプションです）
                  <br />
                  ・プレゼント毎の個数・当選確率の設定
                  <br />
                  ・当社基準によるスパム判定
                  <br />
                  ・当選時に表示する画像と文言の設定
                  <br />
                  ・落選時に表示する画像と文言の設定
                  <br />
                  ・キャンペーン参加者の情報収集・CSV出力
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  最大で何件までリツイートしたユーザーのデータを収集できますか？
                </dt>
                <dd className="qa-list__data">
                  1日10万件以上の収集が可能です。それ以上の件数を収集する必要がある場合は担当者までお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンに参加したTwitterアカウントの情報はどこまで取得できますか？
                </dt>
                <dd className="qa-list__data">
                  Twitterで公開されているプロフィールデータについては基本的に収集できます。個別のアカウントで非公開に設定している情報は取得できません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハズレなしでキャンペーン参加者全員を当選させることは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンに参加したが抽選に外れてしまったユーザーにアプローチするための仕組みはありますか？
                </dt>
                <dd className="qa-list__data">
                  はい、ございます。キャンペーンの設計を工夫してLINEやInstagram、自社サイトなどに誘導することができます。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  景品は何種類まで設定できますか？
                </dt>
                <dd className="qa-list__data">最大3種類まで設定できます。</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  景品設定を2種類でキャンペーン開始しました。途中でもう1つ追加できますか？
                </dt>
                <dd className="qa-list__data">
                  投稿が自動でツイートされ、キャンペーンが始まった後は、その他の景品を追加することはできません。
                  <br />
                  すでに設定した2種類の景品については、キャンペーン開始後も「当選確率」と「当選数」が変更できます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTで開催されるキャンペーンの結果通知はリプライ型とURL型どちらですか？
                </dt>
                <dd className="qa-list__data">
                  URL型です。ダイレクトメッセージで結果をURLで通知します。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/reply-based-or-url-based"
                    className="qa-list__link"
                  >
                    PARKLoTがURL型を採用している理由
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  URL遷移後の結果通知ページで、動画を使った販促はできますか？
                </dt>
                <dd className="qa-list__data">
                  結果通知ページでは、画像・GIF・動画全て表示が可能です。
                  <br />
                  基本プランでは、jpeg/gifファイルの表示のみが可能です。
                  <br />
                  動画の表示も可能ですが、別途料金が発生いたします。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  開催中に当選確率を変更することは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、キャンペーン開催中に管理画面で当選確率を変更することが可能です。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/can-i-change-the-odds" className="qa-list__link">
                    当選確率の変更方法
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  3日間のキャンペーンを予定しています。日別に当選確率を設定できますか？
                </dt>
                <dd className="qa-list__data">
                  連続した3日間のキャンペーンですと、日別の設定はできません。
                  <br />
                  キャンペーン開催中に当選確率の変更が可能ですので、状況を見ながら操作をお願いしております。
                  <br />
                  1日のキャンペーンを3回連続開催であれば、各日ごとに当選確率が設定できます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  開催中に当選者数を変更することは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、キャンペーン開催中に管理画面で当選者数を変更することが可能です。
                  <br />
                  「1万リツイート達成で当選者数が2倍になる！」などのキャンペーン設計ができ、
                  <br />
                  参加者との一体感を生み出すことができます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当選者にフィルターをかけることはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、以下の条件で当選者のフィルター設定ができます。
                  <br />
                  ・デフォルトプロフィールユーザの当選を許可するか
                  <br />
                  ・最低フォロワー数
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTを利用せずに実施したTwitterのリツイートキャンペーンがあります。このキャンペーンに参加したユーザーを後から取得することはできますか？
                </dt>
                <dd className="qa-list__data">
                  お問い合わせください。
                  <br />
                  Twitterの仕様上、通常は直近の100件までしかリツイートユーザーは取得できませんが、
                  <br />
                  ご状況によってはそれ以上の件数が取得できることがあります。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/past-retweet-campaigns"
                    className="qa-list__link"
                  >
                    PARKLoTのリツイートユーザー収集の仕組み
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  レポートとしてどんな情報を確認できますか？
                </dt>
                <dd className="qa-list__data">
                  【管理画面で確認できる情報】
                  <br />
                  ・フォロワー増加数
                  <br />
                  ・キャンペーン参加人数
                  <br />
                  ・当選者（リアルタイム）
                  <br />
                  ・当選者の住所（収集設定オンの場合のみ）
                  <br />
                  ・当選者のメールアドレス（収集設定オンの場合のみ）
                  <br />
                  【CSVでお渡しできる参加者の情報】
                  <br />
                  ・フォロワー数
                  <br />
                  ・フォロー数
                  <br />
                  ・ツイート数
                  <br />
                  ・プロフィール
                  <br />
                  ・リツイート時刻
                  <br />
                  ・企業公式アカウントをフォローしているか
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTシステムを使った住所収集と、プレゼントの受け取り方法文言にURLを入れてGoogleフォーム等で収集するのと、どちらがおすすめですか？
                </dt>
                <dd className="qa-list__data">
                  PARKLoTの住所収集フォームは、Twitterアカウントでのログイン(OAuthサインイン)を要求します。
                  <br />
                  これは、当選者以外がフォームを送信できないようにする仕組みです。
                  <br />
                  PARKLoTの住所収集フォームをご利用いただくと、
                  なりすましや、フォームURLを共有してキャンペーンに当選していない他のユーザーが入力するなどの不正防止に役立ちます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  懸賞ポータルサイト「PARKLoT」のキャンペーン詳細ページは自動でつくられるのでしょうか？
                </dt>
                <dd className="qa-list__data">
                  はい、詳細ページは自動でつくられます。
                  <br />
                  このサイトに掲載されることで、約15,000名（2022年9月末現在）のサイト登録者への拡散が見込めます。
                  <br />
                  さらに、継続してキャンペーンに参加してもらえる可能性も高まります。
                  <br />
                  独自ドメインでの運用にも対応可能です。（別途料金）
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="https://goodluck.park-lot.com/"
                    className="qa-list__link"
                  >
                    懸賞ポータルサイト PARKLoT
                  </a>
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* サポート */}
          <section id="support">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                サポートについて
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Amazonギフト券を配布する場合、PARKLoTへ代理購入を依頼することはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、できます。Amazonギフト券を弊社で代理購入する場合、手数料として10%頂いております。
                  <br />
                  ギフト券代金と手数料は、キャンペーン開催前にご入金ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  SNSキャンペーンの企画や運営のサポートを依頼することは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。詳しくは、お問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  広告の出稿・運用をお願いすることはできますか?
                </dt>
                <dd className="qa-list__data">
                  はい、弊社にお任せください。
                  <br />
                  広告運用の代行手数料についてはお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当選ユーザーへのプレゼントの配送代行は可能ですか？
                </dt>
                <dd className="qa-list__data">
                  当選ユーザー様へ配送代行サービスは行っておりません。当選者様の配送情報はCSVにて出力できます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  認証バッジの取得についてサポートしていただくことはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、できます。詳しくはお問い合わせください。
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* キャンペーン */}
          <section id="campaign">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                キャンペーンについて
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  インスタントウィンとはなんですか？
                </dt>
                <dd className="qa-list__data">
                  インスタントウィンとは、参加したらすぐに抽選結果が分かる懸賞の総称です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Twitterインスタントウィンの仕組みを教えてください。
                </dt>
                <dd className="qa-list__data">
                  キャンペーン期間中はプログラムが指定したツイートをリツイートしたユーザのデータを収集しています。
                  <br />
                  リツイートしたユーザーデータを取得した後、プログラムで自動的に抽選します。抽選結果はTwitterのAPIを利用してダイレクトメッセージで送信されます。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/how-twitter-instant-win-works"
                    className="qa-list__link"
                  >
                    Twitterインスタントウィンの仕組み
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  インスタントウィンキャンペーンは初めてですが、どこまでサポートしてもらえますか？
                </dt>
                <dd className="qa-list__data">
                  大きな目標である販促を見据え、キャンペーンの内容から必要となる画像の素材、
                  <br />
                  運用まで一貫して弊社が作成するスケジュールをもとにサポートさせていただきます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  インスタントウィンを利用する場合、広告課金は必須でしょうか？
                </dt>
                <dd className="qa-list__data">
                  いいえ、必須ではありません。
                  <br />
                  リプライでの結果通知や多数の参加が見込まれる場合は広告課金が必要な場合もございます。お問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  どんなキャンペーンが開催できますか？
                </dt>
                <dd className="qa-list__data">
                  「フォロー＆リツイートキャンペーン」「ハッシュタグキャンペーン」「レシート投稿キャンペーン」が開催できます。
                  <br />
                  抽選方法やプレゼントはターゲットや取り扱っている商品・サービスに合わせて選択できます。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/types-of-campaigns" className="qa-list__link">
                    PARKLoTで開催できるキャンペーンの種類
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンの実施期間や開催頻度のおすすめはありますか？
                </dt>
                <dd className="qa-list__data">
                  3〜7日間を月に2〜4回ほど開催されるアカウントが多いです。
                  <br />
                  効果を高めるコツは、景品内容等を見直しながら、３ヶ月以上継続して開催することです。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/blog/fan-marketing" className="qa-list__link">
                    プロが教えるTwitterインスタントウィン・キャンペーンのやり方
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーン参加は1人1回ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、標準では1日1回まで参加できる設定です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハズレなしのインスタントウィンキャンペーンはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。
                  <br />
                  PARKLoTには「応募者全員プレゼント」機能があります。
                  <br />
                  景品の抽選にはずれてしまった全ての参加者に、クーポンコードやQRコードを送ることができます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  複数のアカウントのフォローを条件に設定できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、インスタントウィンでない後日抽選であれば設定可能です。
                  <br />
                  ただし、参加ユーザーの手間が増えてしまい拡散性が鈍ることがありますので
                  <br />
                  PARKLoTとしてはあまりおすすめしません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンは最短でいつから始められますか？
                </dt>
                <dd className="qa-list__data">
                  Twitterアカウント・キャンペーン名・プレゼント・キャンペーン期間・ツイート投稿用の画像が揃っていれば、最短で10分程度でキャンペーンを開始できます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  どんな応募方法のTwitterキャンペーンができますか？
                </dt>
                <dd className="qa-list__data">
                  ・フォロー＆リツイート
                  <br />
                  ・フォロー＆いいね
                  <br />
                  ・フォロー＆引用リツイート
                  <br />
                  ・フォロー＆ハッシュタグ、など
                  <br />
                  その他の応募方法にも柔軟に対応できます。ぜひお問合せください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  「Twitterキャンペーン手法のうち、いいね（Like）キャンペーンは弊社でしか提供していません」という説明を見ました。PARKLoTではできないのですか？
                </dt>
                <dd className="qa-list__data">
                  PARKLoTでも、いいね（Like）キャンペーンを実施していただけます。
                  <br />
                  「〜〜ならいいね（Like）、〇〇ならリツイート（RT）で応募してね！」というキャンペーンの実施事例もございます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Twitter開発者アカウントの取得、APIキーの取得は必須ですか？
                </dt>
                <dd className="qa-list__data">
                  即時抽選のキャンペーンを実施する場合は、TwitterAPIの取得が必須です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  新設のTwitterアカウントだとAPI申請は大変ですか？
                </dt>
                <dd className="qa-list__data">
                  新設アカウントが特に大変ということはございません。
                  <br />
                  Twitter社とのやりとり時に参照いただける資料も用意していますので、ぜひご活用ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーン開始までの流れを教えてください。
                </dt>
                <dd className="qa-list__data">
                  お申し込み〜データのご準備・お支払い〜キャンペーン作成〜キャンペーン開始、が基本的な流れです。
                </dd>
                <dd className="qa-list__detail">
                  <a href="/qa/starting-a-campaign" className="qa-list__link">
                    キャンペーン開始までの流れ
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンツイートが自動投稿されたあと、主催者がTwitter上でするべきことはありますか？
                </dt>
                <dd className="qa-list__data">
                  抽選は自動で行われるので特に作業は必要ありませんが、
                  <br />
                  さらにキャンペーンを盛り上げるために、該当ツイートを「プロフィールに固定表示」することをおすすめします。
                  <br />
                  また、PARKLoT公式アカウントでも、引用リツイートで拡散を支援します。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  店頭で使えるクーポンを配布することはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  配布したクーポンの使用数は計測できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、使用された枚数が計測できます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ダブルチャンスキャンペーンは実施できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  フォロー＆リツイート後に送られるDMの文言は変えられますか？
                </dt>
                <dd className="qa-list__data">
                  文言は変更できない仕様となります。カスタマイズは別料金にて承ります。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハズレの参加者にもDM送付は可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、ハズレの場合でもDM送信が可能です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当たり・ハズレを合計して、1日に何通までDMを送信できますか？
                </dt>
                <dd className="qa-list__data">
                  Twitterの規定上、1,000通/日が上限です。（認証アカウントは15,000通）
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Amazonギフト券をキャンペーンの景品として配布できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、「Amazonギフト券」をキャンペーンの景品として配布できます。
                  <br />
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/can-i-distribute-amazon-gift"
                    className="qa-list__link"
                  >
                    Amazonギフト券の配布について
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  デジタルギフト券自動配布キャンペーンで、当選者へのDM送信がエラーになりました。未送付のままのギフトコードはどうなりますか？
                </dt>
                <dd className="qa-list__data">
                  次回キャンペーンへの繰越はできません。送信エラーのリストが共有可能ですので、個別の対応をお願いします。
                </dd>
              </dl>

              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  コンビニやスイーツショップで交換できるアイスやお菓子を景品に設定できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、できます。
                  <br />
                  セブンイレブン・ローソン・ファミマなどの各種コンビニやアイスクリームショップと提携しているデジタルギフトを購入するサービスがございます。
                  <br />
                  そちらのサービスからご購入ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  アンケートの回答完了をキャンペーン参加の条件にすることはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、可能です。
                  <br />
                  詳細についてはお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTでキャンペーンを作成する際に、推奨の環境はありますか？
                </dt>
                <dd className="qa-list__data">
                  はい、ブラウザはChromeの使用をおすすめします。
                  <br />
                  （WindowsマシンでChromeを使用している場合でも、まれに不具合が生じる可能性があります）
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を収集して自社サイトに掲載できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、TwitterやInstagramのハッシュタグ付き投稿を自動で収集し、
                  <br />
                  企業サイトに表示することができます。
                  <br />
                  弊社が発行するタグを埋め込むだけで、手軽に始められます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに埋め込む作業も代行依頼できますか？
                </dt>
                <dd className="qa-list__data">
                  サイトへのアクセス権限が必要なので、埋め込み自体は弊社では対応できません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  7日間開催を予定しています。1日のキャンペーンを7回連続開催する（7投稿）のと、7日間連続のキャンペーンを1回する（1投稿）のとでは、どういった違いがありますか？
                </dt>
                <dd className="qa-list__data">
                  7投稿するタイプがおすすめです。
                  <br />
                  広告をかけない場合、2日目以降のインプレッションが下がり参加率が悪くなります。
                  <br />
                  毎日投稿することで、ユーザーの参加を促しやすく、繋ぎ止めやすくなります。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに掲載する際、投稿者からの使用許可は必要ですか？
                </dt>
                <dd className="qa-list__data">
                  いいえ、必要ありません。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに掲載する際、表示する投稿を選択することはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、投稿の表示・非表示を設定できます。
                  <br />
                  基本は非表示に設定されているので、不適切な画像があっても表示はされません。ご安心ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに掲載する際、表示される投稿数はいくつですか？
                </dt>
                <dd className="qa-list__data">
                  デフォルトでは50投稿が表示されます。カスタマイズも別料金で承ります。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに掲載する際、デザインの変更はできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、できます。オプション費用として50,000円〜頂戴しております。
                  <br />
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を自社サイトに掲載する際、投稿の取得は何分おきでしょうか？
                </dt>
                <dd className="qa-list__data">
                  15分に1回の取得となっております。
                  <br />
                </dd>
              </dl>
            </div>
          </section>
          <hr className="border-0 bg-palegrey h-px" />
          {/* その他 */}
          <section id="others">
            <div className="container px-4 py-10 md:py-20 md:px-0">
              <h2 className="text-title text-2xl md:text-3xl font-bold md:mb-8 mb-5">
                その他
              </h2>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンのバナー画像作成をお願いすることはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、弊社で対応できます。詳しくは、担当者までお知らせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンのバナー画像を自社で作成する際の注意事項を教えてください。
                </dt>
                <dd className="qa-list__data">
                  ギフト券の種類によっては、事前に審査が必要なものがございます。詳しくはお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  バナー画像のサイズには指定がありますか？
                </dt>
                <dd className="qa-list__data">
                  バナー画像は1200×675 または
                  1080×1080のサイズを推奨しております。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  「Twitterでログイン」・「アプリ連携」の際、セキュリティは大丈夫ですか？また、課金されてしまったりしませんか？
                </dt>
                <dd className="qa-list__data">
                  Twitterが公開設定している以外の、個人情報などが漏れることはありません。
                  <br />
                  課金もされませんので、安心してご利用いただけます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当選結果通知のDMで、添付ファイルを送ることはできますか?
                </dt>
                <dd className="qa-list__data">
                  画像や動画などの添付ファイルをPARKLoTの機能を使って送る場合は、カスタマイズが必要です。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/can-i-send-attachments-via-dm"
                    className="qa-list__link"
                  >
                    当選結果通知DMの添付ファイルについて
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  当選結果を表示する画面の演出をカスタマイズすることは可能ですか？
                </dt>
                <dd className="qa-list__data">
                  はい、対応可能です。
                  <br />
                  ブランドのイメージに合わせた画像やキャラクターを使用した演出を表示し、参加者に商品名やサービス名を印象付けることが可能となります。
                </dd>
                <dd className="qa-list__detail">
                  <a
                    href="/qa/create-scratch-campaign"
                    className="qa-list__link"
                  >
                    当選結果表示画面のカスタマイズについて
                  </a>
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  インスタントウィンを実施する際、大量の応募があっても遅延等は発生しませんか？
                </dt>
                <dd className="qa-list__data">
                  システムの関係上、まれに遅延が発生してしまうことがあります。
                  <br />
                  そういった場合の対処法として、キャンペーン投稿内のURLから結果を確認できるフローを採用しております。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  フォロー＆RTキャンペーン用ツイートを、プロモ広告（プロモツイート）としても利用したいです。連携できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、連携できます。
                  <br />
                  まずはTwitter広告アカウントでプロモツイートをご投稿ください。
                  <br />
                  その後、該当ツイートのURLを共有いただければ、弊社でPARKLoTインスタントウィンシステムと連携いたします。
                  <br />
                  参加者が該当ツイートをフォロー＆RTすると、自動で抽選が始まりDMが届きます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  プロモツイートと連携する場合、ツイートURLを共有後どれくらいで連携は完了しますか？
                </dt>
                <dd className="qa-list__data">
                  連携完了までに30分前後いただいております。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  プロモツイートと連携する場合、連携完了までの間にフォロー＆RTで応募した参加者はどうなりますか？
                </dt>
                <dd className="qa-list__data">
                  時間を遡ってデータを取得するので、URL共有〜連携完了の間の参加者が漏れることはございません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  Twitterのカルーセル広告でも、PARKLoTと連携してインスタントウィンキャンペーンを行えますか？
                </dt>
                <dd className="qa-list__data">
                  はい、連携できます。投稿後、URLをお知らせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  フォロー＆リツイートキャンペーンをしばらく開催しています。参加数が停滞しているのですが、何か鮮度が上がるような企画はありませんか？
                </dt>
                <dd className="qa-list__data">
                  スクラッチキャンペーン、スロットキャンペーン、診断コンテンツなどが開催可能です。
                  <br />
                  演出効果を加えることで、参加モチベーションを高めることができます。
                  <br />
                  詳しくはお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  スロットキャンペーンの場合、結果通知URLから何度もスロットができてしまいますが、その都度抽選されているのでしょうか？
                </dt>
                <dd className="qa-list__data">
                  いいえ、抽選は1回のみ行われます。
                  <br />
                  スロット画面を何回表示しても、抽選結果は変わりません。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  リツイートユーザーの収集だけでも利用できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、該当ツイートをリツイートしたユーザーのリスト化も承ります。
                  <br />
                  いいねのリスト化、リプライのリスト化も対応しております。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  過去のツイートにリプライしたユーザーへDMの一括送信はできますか？
                </dt>
                <dd className="qa-list__data">
                  リプライ・いいね・リツイートユーザーへのDM一括送信については、お問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  LINEでのおみくじやスクラッチゲームも開催できますか？
                </dt>
                <dd className="qa-list__data">
                  LINEからリンク先に遷移し、LINEログインして抽選する形式に対応しております。（LINE連携）
                  <br />
                  特設サイトの制作、ギフトコード配布、住所収集等可能ですのでお問い合わせください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  リツイートの収集や画像の収集だけを依頼することはできますか？
                </dt>
                <dd className="qa-list__data">はい、ご依頼いただけます。</dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  プレゼントキャンペーン（インスタントウィン）以外の販促はできますか？自社の製品の紹介と認知度UPが目的です。
                </dt>
                <dd className="qa-list__data">
                  はい、Twitterで閲覧・シェアされやすいマンガ広告の制作も承ります。
                  <br />
                  製品をストーリー形式で紹介する、訴求力の高い販促手法です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  企業LINEアカウントへの登録を促したいが、良いプランはありますか？
                </dt>
                <dd className="qa-list__data">
                  はい、コンテンツDM自動配布機能がおすすめです。
                  <br />
                  リツイートしたユーザーに自動でDMを送ることができ、LINEアカウントや企業サイトへのリンク、画像ダウンロードなどを促すことができます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  景品は何日以内に送る必要がありますか？
                </dt>
                <dd className="qa-list__data">
                  特に決まりはありませんが、1週間前後で発送されるケースが多いです。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  キャンペーンの抽選機能を自社サイトに設置することはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、できます。貴社が管理しているサイト内に抽選システムを組み込むことが可能です。
                  <br />
                  費用についてはお問合せください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  PARKLoTを利用してキャンペーンを開催した場合の想定される成果のシミュレーションを出してもらうことはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい。過去に開催したキャンペーンの実績値を参考に想定成果のシミュレーションをお出しすることができますので、是非お問い合わせくださいませ。
                  <br />
                  （成果を保証するものではございません）
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  過去同一のキャンペーンに参加したユーザーに当選できないように設定できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、インスタントウィンでない後日抽選であれば設定可能です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  特設ページは必要ですか？特設ページの制作もPARKLoTに依頼できますか？
                </dt>
                <dd className="qa-list__data">
                  いいえ、通常のインスタントウィンキャンペーンであれば特設ページは不要です。
                  <br />
                  よりブランド価値を高めたい場合や、診断コンテンツによる拡散を御計画の場合は、
                  <br />
                  特設ページの制作から承ります。ぜひご相談ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ブラックリスト登録機能などはありますか？
                </dt>
                <dd className="qa-list__data">
                  今のところ、ブラックリスト登録機能はございません。
                  <br />
                  デフォルトプロフィールアカウント・最低フォロワー人数で絞った当選設定を行っていただけます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  不正抽選を防止する仕組みはありますか？
                </dt>
                <dd className="qa-list__data">
                  はい、デフォルトプロフィールのアカウントを当選させない設定ができます。
                  <br />
                  懸賞目的での複数アカウント応募を一定数防ぐことができます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  オートリプライは何秒で届きますか？遅延はありますか？
                </dt>
                <dd className="qa-list__data">
                  環境によりますが、通常は1分前後で届く仕組みになっています。
                  <br />
                  遅延が発生してしまっても、順次リプライされていきますのでご安心ください。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  ハッシュタグ付き投稿を収集して自社サイトに掲載できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、TwitterやInstagramのハッシュタグ付き投稿を自動で収集し、
                  <br />
                  企業サイトに表示することができます。
                  <br />
                  弊社が発行するタグを埋め込むだけで、手軽に始められます。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  動画の視聴数を増やすためのキャンペーンはできますか？
                </dt>
                <dd className="qa-list__data">
                  はい、動画を視聴してクイズに参加するキャンペーンや
                  <br />
                  リツイートユーザーだけに特別な動画のURLを自動DM送信するキャンペーンも実施可能です。
                </dd>
              </dl>
              <dl className="qa-list__list">
                <dt className="qa-list__question">
                  実店舗でスタンプラリーに参加し、抽選できるようなキャンペーンも依頼できますか？
                </dt>
                <dd className="qa-list__data">
                  はい、対応可能です。詳細についてはお問い合わせください。
                </dd>
              </dl>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Qa
